import React, { useContext } from 'react';
import { GetServerSideProps } from 'next';
import AppshellContext from '../../inc/AppshellContext';
import { AuthenticatedLayout, UnauthenticatedLayout } from 'components/HomeLayout';

const Home = (): JSX.Element => {
  const { user } = useContext(AppshellContext);
  return user ? <AuthenticatedLayout /> : <UnauthenticatedLayout />;
};

export default Home;

export const getServerSideProps: GetServerSideProps = async () => {
  return {
    props: {},
  };
};
